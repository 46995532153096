import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';

@Injectable()
export class CoreService {
  // Set Here Base Url...
  private cache = new Map<string, Observable<any>>();
  constructor(public http: HttpClient) {

  }


 /**
  * @method : jwt
  * @purpose:- Set Authoriztion as Token and also set as Authoriztion..
  */
  private jwt() {
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : null;
    if (token) {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': token
        })
      };
    } else {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
    }
  }

 /**
  * @method: post
  * @purpose: this is post type method is called
  * @param url 
  * @param data 
  */
  public post(url, data) {
    return this.http.post(url, data , this.jwt());
  }


 /**
  * @method: put
  * @purpose: this is put type method is called
  * @param url 
  * @param data 
  */
 public put(url, data) {
  return this.http.put(url, data , this.jwt());
 }


  /**
  * @method: petch
  * @purpose: this is petch type method is called
  * @param url 
  * @param data 
  */
  public patch(url, data =null) {
    return this.http.patch(url, data, this.jwt());
  }


 /**
  * @method: get
  * @purpose: this is get type method is called
  * @param url 
  * @param data 
  */
  // public get(url): Observable<any> {
  // return this.http.get<any>(url, this.jwt());
  // }

 /**
  * @method: delete
  * @purpose: this is get type method is called
  * @param url 
  * @param data 
  */
 public delete(url) {
  this.http.delete(url, this.jwt());
 }
 get(url): Observable<any> {
  const cacheKey = url;

  if (this.cache.has(cacheKey)) {
    return this.cache.get(cacheKey)!; 
  } else {
    const newObservable = this.http.get<any>(url).pipe(
      shareReplay(1),
      tap(() => this.scheduleCacheClear(cacheKey)),
      catchError((error) => {
        console.error('API Error:', error);
        throw error;
      })
    );
    this.cache.set(cacheKey, newObservable); 
    return newObservable;
  }
}
private scheduleCacheClear(cacheKey: string): void {
  setTimeout(() => this.cache.delete(cacheKey), 1000);
}

}
