import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { isBoolean, NewTransactionsValidation,
UsernameValidator,
PasswordValidator,
Required, NotRequired,
ForgotPasswordValidator,
 ForgotUsernameValidator,
ConfirmField, RequiredNumber,
 NotRequiredNumber,
 PasswordRequired, ImagesValid,
NotRequiredFundingDate,
AccNumberValid, ZipValid,
LoanAmountRequired, EmailValidator,
MinLength, CityRequired,
  ContactFieldRequired,
  AbaRoutingRequired,
  PasswordRequiredWithPattern,
  RequiredSelect, NotRequiredSelect, CityPattern, PhonePatternOnly, MultipleEmailValidator
} from '../../shared/classes/validation.class'
import { zip } from 'rxjs/operators';



@Injectable()
export class FormService {
    constructor() { }

    createLoginForm(): FormGroup {
        return new FormBuilder().group({
            username: new UsernameValidator(),
            password: new PasswordValidator(),
        });
    }
    createForgotPasswordForm(): FormGroup {
        return new FormBuilder().group({
            email: new ForgotPasswordValidator()
        });
    }
    createForgotUsernameForm(): FormGroup {
        return new FormBuilder().group({
            email: new ForgotUsernameValidator()
        });
    }

    createRegistrationForm(): FormGroup {
        return new FormBuilder().group({
            username: new UsernameValidator(),
            password: new PasswordValidator(),
        });
    }

    createNewTransForm(): FormGroup {
        return new FormBuilder().group({
            loan_number: new NewTransactionsValidation()
        });
    }

    createClientInfo(): FormGroup {
      return new FormBuilder().group({
        state_code: new NotRequired(),
        clientKey: new NotRequired(),
        lenderCostCenter : new NotRequired(),
        alternet_contact_id: new NotRequired(),
        alternet_contact_first_name: new NotRequired(),
        alternet_contact_last_name: new NotRequired(),
        alternet_contact_email: new EmailValidator(false),
        alternet_contact_phone: new PhonePatternOnly(),
        alternet_contact_ext: new NotRequired()
      });
    }

    /**
     * @function `createBorrowerForm`
     */
    createBorrowerForm(): FormGroup {
      return new FormBuilder().group({
        changeMemo: new NotRequired(),
        primaryBorrower: new FormBuilder().group({
          borrowerCategoryId: new Required(),
          firstName: new MinLength(2),
          middleName: new NotRequired(),
          lastName: new MinLength(2),
          suffix: new NotRequired(),
          // IsNonBorrowerParty: new NotRequired(false),
          IsGuarantor: new NotRequired(false),
          companyName: new Required(),
          phone: new PhonePatternOnly(),
          formation: new NotRequired(),
          dba: new NotRequired(),
          vesting: new NotRequired(),
        }),

        additinalUserChk: new NotRequired(false)

      });
    }

    /**
     * @method : createPropertyForm
     */
    propertyForm(): FormGroup {
      return new FormBuilder().group({
        changeMemo: new NotRequired(),
        addressid: new NotRequired(),
        address1: new MinLength(),
        address2: new NotRequired(),
        city: new CityRequired(2),
        state: new RequiredSelect(),
        zipCode: new ZipValid(true),
        township: new NotRequired(),
        file: [""],
      });
  }
  /**
  * @method : notesForm
  */
  notesForm(): FormGroup {
    return new FormBuilder().group({
      notes: new Required()
    });
  }

    /**
     * @method : loanForm
     */
    loanForm(): FormGroup {
        return new FormBuilder().group({
            changeMemo: new NotRequired(),
            loanNumber: new NewTransactionsValidation(true),
            loanAmount: new LoanAmountRequired(),
            fundingDate: new NotRequiredFundingDate(), //NotRequiredFundingDate
            isSecondMortgage: new NotRequired(false),
            secondMortgageLoanNumber: new NotRequired(),
            isSecondMortgageInsured: new NotRequired('yes'),
            secondMortgageLoanAmount: new LoanAmountRequired()
        });
    }

    /**
     * @method : loanForm
     */
    newClosingAgentForm(): FormGroup {
        return new FormBuilder().group({
            companyName: new Required(),
            email: new EmailValidator(),
            phone: new ContactFieldRequired(),
            address2: new NotRequired(),
            state: new RequiredSelect(),
            website: new NotRequired(),
            contactEmail: new NotRequired(),
            address: new MinLength(),
            city: new CityRequired(2),
            zip: new ZipValid(true)
        });
    }
     /**
     * @method : adduser
     */
    addNewUser(): FormGroup {
        return new FormBuilder().group({
          firstName: new Required(),
          lastName: new  Required(),
          email: new  Required(),
          phone: new NotRequired(),
          mobile: new Required(),
          address: new NotRequired(),
          city: new NotRequired(),
          state: new NotRequired(),
          userrole: new NotRequired(),
        });
    }
         /**
     * @method : addClient
     */
    addNewClientRole(): FormGroup {
        return new FormBuilder().group({
            rolename:new Required(),
            lowerRoleName:new Required(),
            clientRole: new Required()
        });
    }
  /**
     * @method : AddNewParent
     */
    addNewParent(): FormGroup {
        return new FormBuilder().group({
            companyName: new Required(),
            email:new Required(),
            phone: new NotRequired(),
            mobile: new Required(),
            address: new NotRequired(),
            city: new NotRequired(),
            state: new Required(),
            zip: new NotRequired()
        });
  }
  /**
   * @method : AddNewParent
   */
  addNewDocForm(): FormGroup {
    return new FormBuilder().group({
      uploadFile: new Required()
    });
  }
  /**
  * @method : AddNewParent
  */
  rushOrderForm(): FormGroup {
    return new FormBuilder().group({
      checkBox: new Required()
    });
  }
    /**
     * @method : uploadForm
     */
    uploadForm(): FormGroup {
      return new FormBuilder().group({
          CPL: new Required(),
          CPLValidation: new NotRequired(),
          Wire: new Required(),
          EOI: new NotRequired(),
          BankRef: new NotRequired(),
          StateLicense: new NotRequired(),
          FidelityCrimes: new NotRequired()
        });
  }
  cplForm(): FormGroup {
    return new FormBuilder().group({
      CplDate: new NotRequired(),
      CplForm: new NotRequired(),
      CplSignature: new NotRequired(),
      CplSateVerification: new NotRequired(),
    });
  }

    updateUploadForm(): FormGroup {
        return new FormBuilder().group({
            document_type: new Required(),
            file: new Required()
        });
    }
    /**
     * @method: underWriterForm
     */
    underWriterForm(): FormGroup {
        return new FormBuilder().group({
            changeMemo:  new NotRequired(),
            company_name: new Required(),
            company_id: new Required()
        });
    }

    /**
     * @method : `addWireAccount()`
     */
    addWireAccount(): FormGroup {
        return new FormBuilder().group({
            accountHolderInfo: new FormBuilder().group({
              accountName: new Required(),
              accountNumber: new RequiredNumber(), //new AccNumberValid(),
              confirmAccNumber: new RequiredNumber(),
                phone: new PhonePatternOnly(),
                city: new CityPattern(),
                zipCode: new  ZipValid(),
                state: new NotRequiredSelect(),
                subAccountName: new NotRequired(),
              subAccountNumber: new RequiredNumber(false)
            }),
            bankInfo: new FormBuilder().group({
              bankName: new Required(),
              abaRouting: new AbaRoutingRequired(),
                confirmRouting: new NotRequiredNumber(),
                intermediaryBankName: new NotRequired(),
              accountNumber: new RequiredNumber(false)
            }),

        });
    }

    /**
     * @method : `closingAgentInfo`
     */

  closingAgentForm(): FormGroup {
    return new FormBuilder().group({
      companyKey: new NotRequired(),
      title: new NotRequired(),
      companyName: new Required(),
      escrowNumber: new NotRequired(),
      email: new EmailValidator(),
      Phone: new NotRequired(),
      address: new MinLength(8),
      address2: new NotRequired(),
      city: new CityRequired(),
      state: new NotRequiredSelect(),
      zip: new ZipValid(true),
      contactFirstName: new NotRequired(),
      contactLastName: new NotRequired()
      //return new FormBuilder().group({
      //    temp:  new NotRequired(),
      //    company_name: new NotRequired(),
      //    company_id: new NotRequired(),
      //    escrowNumber: new NotRequired(),
      //     agent_id: new Required(),
      //      title: new NotRequired(),
      //      state_code:new NotRequired(),
      //      extra: new FormBuilder().group({
      //          email: new NotRequired(),
      //          telephone: new NotRequired(),
      //          first_name:new NotRequired(),
      //          last_name: new NotRequired(),
      //          contatEmail: new NotRequired(),
      //      }),
      //      address: new FormBuilder().group({
      //          address: new NotRequired(),
      //          address2: new NotRequired(),
      //          city: new NotRequired(),
      //          state_id: new NotRequired(),
      //          zip: new NotRequired()
      //      }),
        });
    }

    /**
     * @method: underWriterForm
     */
    profileForm(): FormGroup {
        return new FormBuilder().group({
            displayName: new Required(),
            surname: new NotRequired(),
            username: new NotRequired(),
            userPrincipalName: new NotRequired(),
            mobilePhone: new PhonePatternOnly(),
            businessPhones: new PhonePatternOnly(),
            extension: new NotRequired(),
            fax: new NotRequired(),
            jobTitle: new NotRequired(),
            profileImage: new NotRequired(),
            roleName: new NotRequired()
        });
    }


    /**
     * @method: changePassword
     */
    changePasswordForm(): FormGroup {
        return new FormBuilder().group({
            oldPassword: new PasswordRequiredWithPattern(),
            newPassword: new PasswordRequiredWithPattern(),
            confirmPassword: new PasswordRequiredWithPattern()
        });
    }

    /**
     * @method : ContatUsForm
     */

    contactUsForm(): FormGroup {
        return new FormBuilder().group({
            phone: new ContactFieldRequired(),
            firstName: new NotRequired(),
            lastName: new NotRequired(),
            email: new EmailValidator()
        });
    }


    /**
     * @metthod :  formService
     * @purpose :  this is created service.
     *
     */


    sendEmail(): FormGroup {
      return new FormBuilder().group({
          fromEmail: new NotRequired(),
          message: new NotRequired(),
          subject: new NotRequired(),
          ccEmails: new NotRequired(),
          emailAddress: new MultipleEmailValidator(),
          attachments: new  FormArray([])
            //emailType: new  Required()
        });
    }

    /**
       * @method : `addWireAccount()`
       */
    wireAndInsuranceForm(): FormGroup {
      return new FormBuilder().group({
        wireInfo: new FormBuilder().group({
         subAccountNumber: new NotRequired(),
          subAccountName: new NotRequired(),
          abaNumber: new NotRequired(),
          accountNumber: new Required(),
          bankName: new NotRequired(),
          nameOnAccount: new Required(),
          accountKey: new NotRequired(),
          isWireNotRequired: new NotRequired(),
          intermediateBankName: new NotRequired(),
          intermediateBankAccountNumber: new NotRequired()
        }),
        insuranceInfo: new FormBuilder().group({
          carrierName: new NotRequired(),
          policyNumber: new Required(),
          policyExpDate: new NotRequired(),
          deductible: new RequiredNumber(),
          singleInstLiabilityLimit: new RequiredNumber(),
          combinedLiability: new RequiredNumber(),
            isInsuranceNotRequired: new NotRequired()
        })
      });
  }

  underwriter(): FormGroup {
    return new FormBuilder().group({
      companyKey: new Required(),
      companyName: new EmailValidator(),
      email: new Required(),
      phone: new NotRequired(),
      address: new Required(),
      address2: new NotRequired(),
      state: new NotRequired(),
      stateName: new Required(),
      city: new CityRequired(),
      zip: new ZipValid(true),
      webSite: new ZipValid(true)
    });
  }
  clientForm(): FormGroup {
    return new FormBuilder().group({
      clientKey: new Required(),
      companyName: new NotRequired(),
      address: new NotRequired(),
      address2: new NotRequired(),
      state: new NotRequired(),
      city: new NotRequired(),
      zipCode: new NotRequired(true),
    });
  }

  /**
    * @method : add additional borrower form
    */
  additionalBorrowerForm(): FormGroup {
    return new FormBuilder().group({
      borrowerCategoryId: new Required(),
      firstName: new Required(),
      lastName: new Required(),
      middleName: new NotRequired(),
      suffix: new NotRequired(),
      isNonBorrowerParty: new NotRequired(false),
      isGuarantor: new NotRequired(false),
      companyName: new Required(),
      phone: new PhonePatternOnly(),
      formation: new NotRequired(),
      dba: new NotRequired(),
    });
  }





    /**
       * @method : `titleInfo()`
       */
    titleDetailsForms(): FormGroup {
      return new FormBuilder().group({
        //Customer Contact Info
        titleComapnyGroup: new FormBuilder().group({
            titleCompany: new Required()
        }),
        //Company
        companyContactInfo: new FormBuilder().group({
          corpType: new Required(),
          companyName: new NotRequired(),
          address: new Required(),
          address2: new NotRequired(),
          city: new NotRequired(),
          fax: new NotRequired(),
          phone: new NotRequired(),
          state: new NotRequired(),
          webSite: new NotRequired(),
          zipCode: new NotRequired(),
          email: new NotRequired(true),
          contactID: new NotRequired(true),
          contactFirstName: new NotRequired(true),
          contactLastName: new NotRequired(true),
          contactAddress: new NotRequired(true),
          contactAddress2: new NotRequired(true),
          contactCity: new NotRequired(true),
          contactState: new NotRequired(true),
          contactZipCode: new NotRequired(true),
          contactExtension: new NotRequired(true),
          contactPhone: new NotRequired(true),
          contactMobilePhone: new NotRequired(true),
          contactFax: new NotRequired(true),
          contactEmail: new NotRequired(true),
          contactDOB: new NotRequired(true),
          contactTitle: new NotRequired(true),
          accountName: new NotRequired(true)
        }),
        //Bank Ref Form
          bankInformationAdmin: new FormBuilder().group({
          bankAccountType: new Required(),
          bankName: new Required(),
          abaNumber: new Required(),
          intermediateBankName: new NotRequired(),
          accountNumber: new Required(),
          accountStartDate: new Required(),
          accountOnNumber: new Required(),
          activeRecord: new NotRequired(),
          subAccountName: new NotRequired(),
          subAccountNumber: new NotRequired(),
          intermedBankName: new NotRequired(),
          intermedBankAccount: new NotRequired(),
          accountName: new NotRequired(),
          address: new ZipValid(false),
          address2: new EmailValidator(false),
          city: new NotRequired(),
          state: new NotRequired(),
          zipCode: new NotRequired(),
          firstName: new NotRequired(),
          lastName: new NotRequired(),
          email: new NotRequired(),
          phone: new NotRequired(),
          contactID: new NotRequired(),
          companyId: new NotRequired()
        }),
          //Insurance
         insuranceInformationAdmin:  new FormBuilder().group({
            contactID: new NotRequired(),
            insuranceName: new NotRequired(),
            insuranceAddress: new Required(),
            insuranceAddress2: new NotRequired(),
            insuranceCity: new NotRequired(),
            insuranceState: new NotRequired(),
            insuranceZipCode: new NotRequired(),
            insurancePolicyType: new NotRequired(),
            insuranceCarrierName: new NotRequired(),
            insuranceBrokerName: new NotRequired(),
            insuranceBrokerRepName: new NotRequired(),

            insuranceBrokerRepEmail: new NotRequired(),
            insuranceBrokerRepPhone: new NotRequired(),
            insurancePolicyNumber: new NotRequired(),
            insurancePolicyExpirationDate: new NotRequired(),
            insuranceDeductible: new NotRequired(),
            insuranceCombineLiability: new NotRequired(),
            insuranceSingleInstLiabilityLimit: new NotRequired(),
            address: new NotRequired(),
            address2: new NotRequired(),
            city: new NotRequired(),
            state: new NotRequired(),
            zipCode: new NotRequired()
          })
       })
  }

     /**
     * @method: changePassword
     */
    resetPassword(): FormGroup {
        return new FormBuilder().group({
            newPassword: new PasswordRequired(),
            confirmPassword: new PasswordRequired()
        });
  }


  /**
  * @method : addClosingAgentContactForm
  */
  closingAgentContactForm(): FormGroup {
    return new FormBuilder().group({
      firstName: new NotRequired(),
      lastName: new NotRequired(),
      email: new EmailValidator(),
      phone: new ContactFieldRequired()
    });
  }

}
