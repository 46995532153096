import { Injectable } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ExistTransactions } from 'src/app/shared/interface/exist_transactions';
import { environment } from '../../../environments/environment';

@Injectable()
export class ClientService extends CoreService {
  public API_ENDPOINT;
  public env;
  constructor(public http: HttpClient) {
    super(http);
    let self = this;
    if (localStorage.getItem("configData")) {
      this.env = JSON.parse(localStorage.getItem("configData"));
      self.API_ENDPOINT = self.env.API_ENDPOINT;
    }
  }
  syncConfig(){
    let self = this;
    if (localStorage.getItem("configData")) {
      this.env = JSON.parse(localStorage.getItem("configData"));
      self.API_ENDPOINT = self.env.API_ENDPOINT;
    }
  }


  isLoanExists(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `DataLoad/isLoanNumberExists`, reqObj);
  }

  getEditModeTransactionIdByLoanNumber(userKey: string, loanNumber:string): Observable<any> {
    return this.get(`${this.API_ENDPOINT}DataLoad/GetEditModeTransactionIdByLoanNumber?userKey=${userKey}&loanNumber=${loanNumber}`);
  }

  clientSearch(reqObj = {}): Observable<any> {
    return this.get(this.API_ENDPOINT + `DataLoad/SearchClientByStateCodeAndUserId?clientKey=${reqObj['clientKey']}&userKey=${reqObj['userKey']}&stateCode=${reqObj['state']}&isEditOrder=${reqObj['isEditOrder']}&transactionKey=${reqObj['transactionKey']}`)
  }

  /**
   * @method: getState
   * @return `List of State`
   */
  getState(): Observable<any> {
    return this.get(`assets/api/state.json`)
  }

  uploadDoc(reqObj): Observable<any> {
    return this.post('uploadDoc', reqObj);

  }

  searchInsurance(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `DataLoad/GetInsuranceCompanyListBySearchKey?clientKey=${reqObj['clientKey']}&userKey=${reqObj['userKey']}`);
  }


  /**
  *  @service `Get  NotesList`
  *  @parmas : added `Obj as user id or Client Id`
  *  `Namespace` : @getAgents
  */
  getFiltersList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Filter/GetFilters?createdby=${reqObj['userKey']}`);
  }

  /**
  *  @service `Get  Filter On Click`
  *  @parmas : added `Obj as user id or Client Id`
  *  `Namespace` : @getAgents
  */
  getFilterOnClick(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Transaction/AddNewWiredAccount?transactionKey=${reqObj["transactionKey"]}&clientKey=${reqObj["clientKey"]}&userKey=${reqObj['userKey']}`);
  }




  saveFilter(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `Filter/AddFilter`, reqObj);
  }
  addWireAccount(reqObj) {
    return this.post(this.API_ENDPOINT + `Transaction/AddNewWiredAccount`, reqObj);
  }

  /**
   * @service `Add New Agent`
   * @parmas : added `Obj`
   * `Namespace` : @addAgent
   */
  addAgent(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `Transaction/AddNewClosingAgent`, reqObj);
  }
  /**
    * @service `Add Notes`
    * @parmas : added `Obj`
    * `Namespace` : @addNote
    */
  addNote(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `Transaction/AddNote`, reqObj);
  }

  /**
   *  @service `Get  Agents`
   *  @parmas : added `Obj as user id or Client Id`
   *  `Namespace` : @getAgents
   */
  getAgents(reqObj): Observable<any> {
    //return this.get(this.API_ENDPOINT + `DataLoad/GetClosingAgentList?state=${reqObj["state"]}&clientKey=${reqObj["clientKey"]}&userKey=${reqObj['userKey']}&sort=${reqObj['sort']}`);
    return this.post(this.API_ENDPOINT + `DataLoad/GetClosingAgentList`, reqObj);
  }
  /**
  *  @service `Get  NotesList`
  *  @parmas : added `Obj as user id or Client Id`
  *  `Namespace` : @getAgents
  */
  getNotesListByTransId(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `DataLoad/GetNotesListByTransId?transactionKey=${reqObj["transactionKey"]}&clientKey=${reqObj["clientKey"]}&userKey=${reqObj['userKey']}`);
  }

  /**
   *  @service `Get  Orders`
   *  @parmas : added `Obj as user id or Client Id`
   *  `Namespace` : @getAgents
   */
  getOrders(reqObj): Observable<any> {
    return this.get(`assets/api/order-list.json`);
  }

  /**
   *  @service `Update  Borrower Info`
   *  @parmas : added `Obj as user id or Client Id`
   *  `Namespace` : @updateBorrower
   */
  updateBorrower(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `Transaction/updateBorrowerByOrderId?orderKey=${reqObj['transactionKey']}&userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}`, reqObj);
  }

  /**
   *  @service `Update  Common method which help updated all tab data. service.`
   *  @parmas : added `Obj as user id or Client Id`
   *  `Namespace` : @updateBorrower
   */
  updateTab(method_name, reqObj, loanId, clientId, userId): Observable<any> {
    return this.get(`${method_name}`);
  }


  uploadDocument(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `Transaction/uploadDoc`, reqObj);
  }

  updateLoanData(reqObj): Observable<any> {
    let newObject = { ...reqObj };
    return this.patch(this.API_ENDPOINT + `Transaction/updateLoanDetailsByOrderId?orderKey=${reqObj['transactionKey']}&userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}`, newObject);
  }

  updateUnderData(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `Transaction/updateUnderWriterByOrderId?orderKey=${reqObj['transactionKey']}&userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}`, reqObj);
  }
  updatePropertyData(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `Transaction/updatePropertyInfoByOrderId?orderKey=${reqObj['transactionKey']}&userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}`, reqObj);
  }


  getWireInfo(clientObj) {
    return this.get(this.API_ENDPOINT + `DataLoad/GetTitleWiredInfo?titleKey=${clientObj['titleKey']}&clientKey=${clientObj['clientKey']}&companyKey=${clientObj['companyKey']}&userKey=${clientObj['userKey']}`);
  }

  getTransactionList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Transaction/existingTransactionList?userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}&userType=${reqObj['userType']}&periodToLoad=${reqObj['periodToLoad']}&selectedReport=${reqObj['selectedReport']}&page=${reqObj['page']}&size=${reqObj['size']}&startRow=${reqObj['startRow']}&endRow=${reqObj['endRow']}&filterModel=${reqObj['filterModel']}`);
  }

  GetExistingOrderList(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `Transaction/GetExistingOrderList`, reqObj);
  }

  getAlternetInfo(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `DataLoad/GetAlternateUsersInCompany?userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}`);
  }

  getAddress(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `DataLoad/GetTitleCompanyByCompanyId?companyKey=${reqObj['companyKey']}&clientKey=${reqObj['clientKey']}&userKey=${reqObj['userKey']}`);
  }

  createOrder(reqObj): Observable<any> {
    let reqObj1 = JSON.stringify(reqObj);
    return this.post(this.API_ENDPOINT + `Transaction/CreateNewOrder`, reqObj1);
  }
  updateOrderTransaction(reqObj): Observable<any> {
    let reqObj1 = JSON.stringify(reqObj);
    return this.post(this.API_ENDPOINT + `Transaction/UpdateOrderTransaction`, reqObj1);
  }
  saveOrderClientInfo(reqObj): Observable<any> {
    let reqObj1 = JSON.stringify(reqObj);
    return this.post(this.API_ENDPOINT + `Transaction/SaveOrderClientInfo`, reqObj1);
  }
  updateTransactionBorrower(reqObj): Observable<any> {
    let reqObj1 = JSON.stringify(reqObj);
    return this.post(this.API_ENDPOINT + `Transaction/UpdateTransactionBorrower`, reqObj1);
  }
  updateOrderProperty(reqObj): Observable<any> {
    let reqObj1 = JSON.stringify(reqObj);
    return this.post(this.API_ENDPOINT + `Transaction/UpdateOrderProperty`, reqObj1);
  }
  updateOrderPropertyUpSert(reqObj): Observable<any> {
    let reqObj1 = JSON.stringify(reqObj);
    return this.post(this.API_ENDPOINT + `Transaction/UpdateOrderPropertyUpSert`, reqObj1);
  }
  newPropertyUpSert(reqObj, transactionId): Observable<any> {
    let reqObj1 = JSON.stringify(reqObj);
    return this.post(this.API_ENDPOINT + `AdminTransaction/NewPropertyUpSert?TransactionId=` + transactionId, reqObj1);
  }
  getAllNewProperty(transactionId): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/GetAllNewProperty?TransactionId=` + transactionId);
  }

  async newPropertyBulkUpSert(payload: any, transactionId): Promise<any> {

    const options = {
      method: 'POST', // GET, POST, PUT, DELETE.
      headers: {
        contentType: "multipart/form-data",
      },
      body: payload.uploadedFile,
    };

    const responseBackData = await fetch(this.API_ENDPOINT + 'AdminTransaction/NewPropertyBulkUpSert?TransactionId=' + transactionId, options);
    return responseBackData.json();
  }
  deleteNewProperty(addressId): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/DeleteNewProperty?AddressId=` + addressId);
  }
  updateTransactionLoanInfo(reqObj): Observable<any> {
    let reqObj1 = JSON.stringify(reqObj);
    return this.post(this.API_ENDPOINT + `Transaction/UpdateTransactionLoanInfo`, reqObj1);
  }
  updateTransactionClosingAgentSimple(reqObj): Observable<any> {
    let reqObj1 = JSON.stringify(reqObj);
    return this.post(this.API_ENDPOINT + `Transaction/UpdateTransactionClosingAgentSimple`, reqObj1);
  }
  getTransactionClosingAgentSimple(transactionId: number): Observable<any> {
    return this.get(this.API_ENDPOINT + `Transaction/GetTransactionClosingAgentSimple?transactionId=` + transactionId);
  }
  saveOrderContactInfo(reqObj): Observable<any> {
    let reqObj1 = JSON.stringify(reqObj);
    return this.post(this.API_ENDPOINT + `Transaction/SaveOrderContactInfo`, reqObj1);
  }
  updateTransactionWireInfo(reqObj): Observable<any> {
    let reqObj1 = JSON.stringify(reqObj);
    return this.post(this.API_ENDPOINT + `Transaction/UpdateTransactionWireInfo`, reqObj1);
  }
  transactionIsEditingComplete(transactionId): Observable<any> {
    return this.get(this.API_ENDPOINT + `Transaction/TransactionIsEditingComplete?TransactionId=` + transactionId);
  }
  duplicateOrder(transactionId, userid, loanNumber): Observable<any> {
    var paramObj : any = {};
    paramObj.transactionId = transactionId;
    paramObj.userid = userid;
    paramObj.loanNumber = loanNumber;
    return this.post(this.API_ENDPOINT + `Transaction/DuplicateOrder`, paramObj);
  }
  updateTransactionUnderwriter(reqObj): Observable<any> {
    let reqObj1 = JSON.stringify(reqObj);
    return this.post(this.API_ENDPOINT + `Transaction/UpdateTransactionUnderwriter`, reqObj1);
  }
  updateOrderInsertFiles(reqObj): Observable<any> {
    let reqObj1 = JSON.stringify(reqObj);
    return this.post(this.API_ENDPOINT + `Transaction/UpdateOrderInsertFiles`, reqObj1);
  }
  updateOrderNotesInfo(reqObj): Observable<any> {
    let reqObj1 = JSON.stringify(reqObj);
    return this.post(this.API_ENDPOINT + `Transaction/UpdateOrderNotesInfo`, reqObj1);
  }
  updateOrderFinal(transactionId, userID, reqObj): Observable<any> {
    let reqObj1 = JSON.stringify(reqObj);
    return this.post(this.API_ENDPOINT + `Transaction/UpdateOrderFinal?TransactionId=` + transactionId + `&userKey=` + userID, reqObj1);
  }


  rushOrderConvert(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `Transaction/ConvertToRushOrder?transactionKey=${reqObj['transactionKey']}&userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}`);
  }

  getOrderStatus(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Transaction/GetOrderStatus?userKey=${reqObj['userKey']}&userType=${reqObj['userType']}&fromDate=${reqObj['fromDate']}&toDate=${reqObj['toDate']}&report=${reqObj['report']}`);
  }

  getDocumentStatus(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Transaction/GetDocumentStatus?userKey=${reqObj['userKey']}&userType=${reqObj['userType']}&fromDate=${reqObj['fromDate']}&toDate=${reqObj['toDate']}&report=${reqObj['report']}`);
  }

  getDashboardTransactionList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Transaction/getDashboardTransactionList?userKey=${reqObj['userKey']}&userType=${reqObj['userType']}&fromDate=${reqObj['fromDate']}&toDate=${reqObj['toDate']}&report=${reqObj['report']}&status=${reqObj['status']}&docType=${reqObj['docType']}`);
  }

  getRequiredDocTypes(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Transaction/getRequiredDocTypes?userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}&clientCompanyKey=${reqObj['clientCompanyKey']}&state=${reqObj['state']}`);
  }

  deleteFilter(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `Filter/RemoveFilter`, reqObj);
  }

  getFilter(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Transaction/getFilter?userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}&clientCompanyKey=${reqObj['clientCompanyKey']}&state=${reqObj['state']}`);
  }
  checkCertificateGenerate(reqObj): Observable<any> {
    if (reqObj && reqObj.orderKey && reqObj.orderKey != undefined) {
      return this.get(this.API_ENDPOINT + `DataLoad/CheckCertificateGenerate?orderKey=${reqObj['orderKey']}&LoggedInUser=${reqObj['userKey']}`);
    }
    else {
      return null;
    }
  }

  removeAdditionalBorrower(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Transaction/RemoveAdditionalBorrower?userKey=${reqObj['userKey']}&borrowerId=${reqObj['borrowerId']}`);
  }
  getAdditionalBorrowers(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Transaction/GetAdditionalBorrowers?userKey=${reqObj['userKey']}&transid=${reqObj['transid']}`);
  }

  GetBorrowerCategoryList(userKey: string): Observable<any> {
    return this.get(this.API_ENDPOINT + 'Transaction/GetBorrowerCategoryList?userKey=' + userKey);
  }

  sendNotesEmail(reqObj) {
    return this.post(this.API_ENDPOINT + `Transaction/SendEmailDoc`, reqObj);
  }

  GetClosingAgentContactsActive(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Transaction/GetClosingAgentContactsActive?CompanyID=${reqObj['CompanyID']}&userKey=${reqObj['userKey']}`);
  }

  GetOrderContactInfo(TransactionKey): Observable<any> {
    return this.get(this.API_ENDPOINT + `Transaction/GetOrderContactInfo?TransactionKey=${TransactionKey}`);
  }

  AddClosingAgentContacts(userKey, reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `Transaction/AddClosingAgentContacts?userKey=${userKey}`, reqObj);
  }
  AddClosingAgentContact(userKey, reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `Transaction/AddClosingAgentContact?userKey=${userKey}`, reqObj);
  }

}
